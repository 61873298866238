"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var wait_middleware_1 = require("@codebuild/uikit/components/middlewares/wait.middleware");
var environment_1 = require("@codebuild/uikit/libs/environment");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var on_login_1 = require("@codebuild/uikit/modules/codebuild/authentication/libs/on-login");
var routes_1 = require("@codebuild/uikit/modules/codebuild/authentication/routes");
var facebook_auth_1 = require("@codebuild/uikit/modules/social/facebook/facebook.auth");
var google_auth_1 = require("@codebuild/uikit/modules/social/google/google.auth");
var React = require("react");
var accept_invitation_component_1 = require("../modules/authentication/components/accept-invitation.component");
var forgot_password_request_component_1 = require("../modules/authentication/components/forgot-password-request.component");
var forgot_password_reset_component_1 = require("../modules/authentication/components/forgot-password-reset.component");
var login_component_1 = require("../modules/authentication/components/login.component");
var registration_component_1 = require("../modules/authentication/components/registration.component");
var login_layout_1 = require("../modules/authentication/layouts/login.layout");
var forgot_password_screen_1 = require("../modules/authentication/screens/forgot-password.screen");
var login_screen_1 = require("../modules/authentication/screens/login.screen");
var registration_screen_1 = require("../modules/authentication/screens/registration.screen");
var detailed_error_1 = require("../modules/common/components/errors/detailed-error");
var handle_toasts_1 = require("../modules/common/libs/handle.toasts");
var redirected_shoprenter_user_screen_1 = require("../modules/authentication/screens/redirected-shoprenter-user.screen");
var authorized_middleware_1 = require("../modules/common/middlewares/authorized.middleware");
var loginLayout = login_layout_1.LoginLayout.withProps({
    hideCategories: true,
    width: {
        mobile: 16,
        tablet: 16,
        computer: 14,
        largeScreen: 12,
        widescreen: 12,
    }
});
var socialProviders = {
    facebook: new facebook_auth_1.FacebookAuth(environment_1.Environment.get('facebook')),
    google: new google_auth_1.GoogleAuth(environment_1.Environment.get('google')),
};
module.exports = __spreadArrays(routes_1.routes({
    login: {
        middlewares: [
            new wait_middleware_1.WaitMiddleware(1000),
        ],
        layout: loginLayout,
        screen: login_screen_1.LoginScreen.withProps({
            loginComponent: login_component_1.LoginComponent.withProps({
                showError: true,
                showSuccess: true,
                renderError: function (ctx, error) { return React.createElement(detailed_error_1.DetailedError, { error: error }); }
            }),
            repository: repository_1.Repository.use('default'),
            allowSocialAuth: true,
            social: socialProviders,
            onLoginSuccess: function (response) { return on_login_1.onLogin(response, '/'); },
            onLoginError: function (response) { return console.log(response); },
        })
    },
    registration: {
        middlewares: [
            new authorized_middleware_1.AuthorizedMiddleware({
                redirectTo: '/login',
                adminOnly: true
            })
        ],
        layout: loginLayout,
        screen: registration_screen_1.RegistrationScreen.withProps({
            endpoint: '/p/register',
            onRegistration: function (type, value) { return router_provider_1.RouterProvider.goTo('/login'); },
            registrationComponent: registration_component_1.RegistrationComponent.withProps({
                showError: true,
                showSuccess: true,
                renderError: function (ctx, error) { return React.createElement(detailed_error_1.DetailedError, { error: error }); }
            }),
            repository: repository_1.Repository.use('default'),
            allowSocialRegistration: true,
            social: socialProviders,
            onRegistrationError: function (type, value) { return console.log(type, value); },
            onRegistrationSuccess: function (type, value, credentials) { return local_store_1.LocalStore.setItem('SuccessRegCredentials', {
                type: type,
                email: value === null || value === void 0 ? void 0 : value.email,
                credentials: credentials
            }); }
        })
    },
    forgotPassword: {
        middlewares: [
            new wait_middleware_1.WaitMiddleware(1000)
        ],
        layout: loginLayout,
        screen: forgot_password_screen_1.ForgotPasswordScreen.withProps({
            repository: repository_1.Repository.use('default'),
            onRequestSuccess: function (response) { return console.log(response); },
            onRequestError: function (error) { return console.log(error); },
            onResetSuccess: function (response) { return onResetSuccess(response); },
            onResetError: function (error) { return console.log(error); },
            forgotPasswordRequestComponent: forgot_password_request_component_1.ForgotPasswordRequestComponent.withProps({
                showError: true,
                showSuccess: true,
                renderError: function (ctx, error) { return React.createElement(detailed_error_1.DetailedError, { error: error }); },
                renderSuccess: function (ctx, error) { return ctx.setState({ renderSuccessContent: true }); },
            }),
            forgotPasswordResetComponent: forgot_password_reset_component_1.ForgotPasswordResetComponent.withProps({
                showError: true,
                showSuccess: true,
                renderError: function (ctx, error) { return React.createElement(detailed_error_1.DetailedError, { error: error }); },
            })
        })
    }
}), [
    {
        middlewares: [new wait_middleware_1.WaitMiddleware(1000)],
        path: '/accept-invitation',
        layout: loginLayout,
        component: accept_invitation_component_1.AcceptInvitationComponent
    },
    {
        middlewares: [new wait_middleware_1.WaitMiddleware(1000)],
        path: '/redirected-shoprenter-app-user',
        layout: loginLayout,
        component: redirected_shoprenter_user_screen_1.RedirectedShoprenterAppUserScreen
    },
]);
var onResetSuccess = function (response) {
    handle_toasts_1.HandleToasts.success(trans_1.trans('auth.reset-password.success'));
    router_provider_1.RouterProvider.goTo('/login');
};
