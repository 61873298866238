"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopChangerComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var responsive_component_1 = require("@codebuild/uikit/libs/libs/responsive.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var local_store_1 = require("@codebuild/uikit/libs/store/local.store");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var React = require("react");
var operators_1 = require("rxjs/operators");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var pagination_list_control_1 = require("../repository-list/controls/pagination-list-control");
var repository_list_1 = require("../repository-list/repository-list");
require("./menu.component.scss");
var get_access_token_1 = require("@codebuild/uikit/modules/codebuild/authentication/libs/get-access-token");
var miraExists = false;
var ShopChangerComponent = (function (_super) {
    __extends(ShopChangerComponent, _super);
    function ShopChangerComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fallbackLogo = 'https://react.semantic-ui.com/images/avatar/small/daniel.jpg';
        _this.state = {
            isOpen: false,
        };
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 100000000,
                page: 0,
                query: ''
            }
        });
        _this.search = new form_builder_1.FormBuilder({
            fields: {
                query: new field_1.Field({
                    name: 'query',
                    label: 'Keresés',
                    placeholder: 'Keresés a webáruházaim között',
                    value: ''
                })
            }
        });
        return _this;
    }
    ShopChangerComponent.prototype.isLoggedIn = function () {
        var user = this.getCurrentUser();
        var accessToken = get_access_token_1.getAccessToken();
        return user && (user === null || user === void 0 ? void 0 : user.email) && accessToken && accessToken.includes('Bearer');
    };
    ShopChangerComponent.prototype.componentDidMount = function () {
        var _this = this;
        var _a, _b, _c, _d;
        _super.prototype.componentDidMount.call(this);
        this.$subscriptions.push(this.search.$value
            .pipe(operators_1.debounceTime(400))
            .subscribe(function (value) {
            _this.control.set('query', value === null || value === void 0 ? void 0 : value.query);
            _this.control.set('page', 0);
        }));
        if (!miraExists && this.isLoggedIn()) {
            var currentShop = session_storage_1.SessionStorage.getItem('SelectedShop');
            miraExists = true;
            console.log('ShopChangerComponent componentDidMount if (this.isLoggedIn()) --> loading chatbot...');
            var configScript = document.createElement("script");
            configScript.innerHTML = "\n              window.embeddedChatbotConfig = {\n                chatbotId: \"wATBjpaxzKnXupmU4h3ao\",\n                domain: \"www.chatbase.co\"\n              };\n              window.chatbaseUserConfig = {\n                user_id: \"" + this.getCurrentUser()._id + "\",\n                user_metadata: {\n                    \"shop_id\": \"" + currentShop._id + "\",\n                    \"integration_id\": \"" + currentShop.integrationId + "\",\n                    \"email\": \"" + currentShop.email + "\",\n                    " + (((_a = currentShop.billingData) === null || _a === void 0 ? void 0 : _a.companyName) ? "\"company\": \"" + ((_b = currentShop.billingData) === null || _b === void 0 ? void 0 : _b.companyName) + "\"," : '') + "\n                    " + (((_c = currentShop.billingData) === null || _c === void 0 ? void 0 : _c.taxNumber) ? "\"tax_number\": \"" + ((_d = currentShop.billingData) === null || _d === void 0 ? void 0 : _d.taxNumber) + "\"," : '') + "\n                    \"webshop_url\": \"" + currentShop.url + "\",\n                    \"webshop_name\": \"" + currentShop.title + "\",\n                }\n              };\n            ";
            document.head.appendChild(configScript);
            console.log('ShopChangerComponent componentDidMount if (this.isLoggedIn()) --> inserted config script');
            setTimeout(function () {
                console.log('ShopChangerComponent componentDidMount if (this.isLoggedIn()) --> loading 3rd party script...');
                var script = document.createElement("script");
                script.src = "https://www.chatbase.co/embed.min.js";
                script.setAttribute("chatbotId", "wATBjpaxzKnXupmU4h3ao");
                script.setAttribute("domain", "www.chatbase.co");
                script.defer = true;
                document.body.appendChild(script);
                console.log('ShopChangerComponent componentDidMount if (this.isLoggedIn()) --> inserted 3rd party script');
            }, 100);
        }
    };
    ShopChangerComponent.prototype.render = function () {
        var _this = this;
        var currentShop = session_storage_1.SessionStorage.getItem('SelectedShop');
        var trigger = React.createElement("div", { className: 'ShopChanger display-flex align-items-center' },
            React.createElement("div", { className: 'ImageWrapper' }),
            React.createElement("span", { className: 'flex-fill' }, currentShop === null || currentShop === void 0 ? void 0 : currentShop.title),
            React.createElement(semantic_ui_react_1.Icon, { name: 'angle down' }));
        return React.createElement(semantic_ui_react_1.Modal, { trigger: trigger, size: "tiny", className: 'ShopModal', closeIcon: true, open: this.state.isOpen, onOpen: function () { return _this.setState({ isOpen: true }); }, onClose: function () { return _this.setState({ isOpen: false }); } },
            React.createElement(semantic_ui_react_1.Modal.Header, { className: 'display-flex justify-content-between align-items-center' },
                React.createElement(semantic_ui_react_1.Header, { as: 'h3' }, trans_1.trans('menu.shop-selector.title'))),
            React.createElement(semantic_ui_react_1.Modal.Content, null,
                React.createElement(semantic_ui_react_1.Grid, { columns: 1 },
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Form, null,
                            React.createElement(form_control_1.FormControl, { field: this.search.fields.query, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                                React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input })),
                            React.createElement(semantic_ui_react_1.Icon, { name: 'search' }))),
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(repository_list_1.RepositoryList, { control: this.control, endpoint: "/u/user-shop", repository: repository_1.Repository.use('default'), renderEmpty: function () { return React.createElement(semantic_ui_react_1.Header, { as: 'h4', textAlign: 'center', className: 'py-4' }, "Nincs a keres\u00E9snek megfelel\u0151 eredm\u00E9ny"); }, renderItemsWrapper: function (ctx) { return React.createElement(semantic_ui_react_1.List, null, ctx.renderItems(ctx)); }, renderItem: function (ctx, item, index) {
                                var isCurrent = (currentShop._id === item._id);
                                var title = isCurrent
                                    ? trans_1.trans('menu.shop-selector.selected')
                                    : trans_1.trans('menu.shop-selector.not-selected');
                                var onClick = isCurrent
                                    ? function () { return void 0; }
                                    : function () { return __awaiter(_this, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    session_storage_1.SessionStorage.deleteItem('SelectedShop');
                                                    return [4, router_provider_1.RouterProvider.updateQuery({ shop: item._id })];
                                                case 1:
                                                    _a.sent();
                                                    window.location.reload();
                                                    return [2];
                                            }
                                        });
                                    }); };
                                return React.createElement(semantic_ui_react_1.List.Item, { key: item._id, className: "ShopListItem display-flex align-items-center" },
                                    React.createElement("div", { className: 'ImageWrapper' },
                                        React.createElement(semantic_ui_react_1.Image, { src: item.logo || _this.fallbackLogo })),
                                    React.createElement(semantic_ui_react_1.List.Content, { className: "flex-fill" },
                                        React.createElement(semantic_ui_react_1.List.Header, { as: 'h4' }, item.title),
                                        React.createElement(semantic_ui_react_1.List.Description, null, item.url)),
                                    React.createElement(semantic_ui_react_1.Button, __assign({ content: title }, (isCurrent ? { style: { backgroundColor: '#0077FF' } } : null), { onClick: function () { return onClick(); } })));
                            } })))));
    };
    ShopChangerComponent.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    ShopChangerComponent.prototype.getCurrentUser = function () {
        return local_store_1.LocalStore.getItem('User');
    };
    return ShopChangerComponent;
}(responsive_component_1.ResponsiveComponent));
exports.ShopChangerComponent = ShopChangerComponent;
